import type { MIDDLEWARES } from "./middlewares";

export enum PopoverPlacement {
    Bottom = "bottom",
    BottomEnd = "bottom-end",
    BottomStart = "bottom-start",
    Left = "left",
    LeftEnd = "left-end",
    LeftStart = "left-start",
    Right = "right",
    RightEnd = "right-end",
    RightStart = "right-start",
    Top = "top",
    TopEnd = "top-end",
    TopStart = "top-start",
}

export enum PopoverStrategy {
    Absolute = "absolute",
    Fixed = "fixed",
}

export enum PopoverTheme {
    Dark = "dark",
    Light = "light",
}

export interface PopoverMiddleware {
    options?: Partial<(typeof MIDDLEWARES)[keyof typeof MIDDLEWARES]>;
    type: keyof typeof MIDDLEWARES;
}
