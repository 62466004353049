import type { HTTPError } from "ky";

import { ApiPath } from "@affluent/agency-os-api-types-global";
import {
    getResponseBody,
    getResponseType,
    ResponseType,
} from "@affluent/frontend-util-http-api-request";

import { logOutAndGoToLogin } from "../../utils/auth/logOutAndGoToLogin.util";
import { SentryService } from "../sentry";

export async function getResponseErrorMessage(
    error: HTTPError,
    requestPath: string,
): Promise<string> {
    const { message, request, response } = error;

    if (response) return handleResponseError(response, request, requestPath);

    SentryService.captureException(error, { request });

    return message;
}

const handleResponseError = async (
    response: Response,
    request: Request,
    requestPath: string,
): Promise<string> => {
    switch (requestPath) {
        case ApiPath.AdminUserLogin:
            return handleLoginErrorResponse(response, request);
        case ApiPath.AdminUserLogout:
            return handleLogOutErrorResponse(response, request);
    }
    return handleResponseByStatus(response, request);
};

export const handleLoginErrorResponse = async (
    response: Response,
    request: Request,
) => {
    if (response.status === 401) return "Email or password is incorrect";
    return handleResponseByStatus(response, request);
};

export const handleLogOutErrorResponse = async (
    response: Response,
    request: Request,
) => {
    if (response.status === 401) return;
    return handleResponseByStatus(response, request);
};

export const handleResponse400 = async (
    response: Response,
    request?: Request,
) => {
    const body = await getResponseBody(response);

    SentryService.captureMessage("Request returned a 400 error", {
        request,
        body,
    });

    return body?.error || "Request error occurred. Please, try again later.";
};

export const handleResponse401 = async (response: Response) => {
    const body = await getResponseBody(response);

    await logOutAndGoToLogin();

    return body?.error || "User is not authorized for the request.";
};

export const handleResponse404 = async (
    response: Response,
    request?: Request,
) => {
    const body = await getResponseBody(response);

    SentryService.captureMessage(
        "Request returned a 404 error",
        { body, request },
        { level: "warning" },
    );

    return body?.error || "Request not found. Please, try again later.";
};

const handleResponse500 = async (response: Response) => {
    const body = await getResponseBody(response);

    return (
        body?.error ||
        "Internal Server Error occurred. Please, try again later."
    );
};

async function handleResponseByStatus(response: Response, request: Request) {
    switch (response.status) {
        case 400:
            return handleResponse400(response, request);

        case 401:
            return handleResponse401(response);

        case 404:
            return handleResponse404(response, request);

        case 500:
            return handleResponse500(response);

        default: {
            const responseType = getResponseType(response);

            if (responseType === ResponseType.Json) {
                const body = await getResponseBody(response);

                return body?.error || "Unknown error occurred.";
            }

            return "Internal Server Error occurred. Please, try again later.";
        }
    }
}
